
cei.page.category = (function (window, $) {

    /**
     * @description jQuery DOM Ready Function
     */
    $(function () {
        var backToTop = $('#back2Top');
        var win = $(window);

        /**
         * @description scroll up to the top of the page upon clicking the "backToTop" image
         */
        backToTop.on('click', function (event) {
            event.preventDefault();
            event.stopPropagation();
            $("html, body").animate({ scrollTop: 0 }, "slow");
        });

        /**
         * @description fade in the "backToTop" image upon the page is scrolled down from the top at least 200 pixels  
         */
        win.on('scroll', function () {
            var height = win.scrollTop();
            if (height > 200) {
                backToTop.fadeIn();
            } else {
                backToTop.fadeOut();
            }
        });

        // scroll to top
        $('html, body').animate({ scrollTop: 0 }, 'slow');
    });

})(window, window.jQuery);
